import { useFetchComputeJobResultMutation } from "@decentriq/graphql/dist/hooks";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Divider,
  Modal,
  ModalDialog,
} from "@mui/joy";
import { AlertTitle } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import {
  useApiCore,
  useComputeNodesVars,
  usePublishedDataRoom,
} from "contexts";
import { mapErrorToGeneralSnackbar, useDataRoomSnackbar } from "hooks";
import { getSyntheticDataReportFromResults } from "utils/apicore";

interface SyntheticDataReportDialogProps {
  open: boolean;
  jobId: string;
  computeNodeId: string;
}

const SyntheticDataReportDialog: React.FC<SyntheticDataReportDialogProps> = ({
  open,
  jobId,
  computeNodeId,
}) => {
  const { closeSdgQualityReportDialog } = useComputeNodesVars();
  const { dataRoomId: dcrHash, driverAttestationHash } = usePublishedDataRoom();
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { store } = useApiCore();
  const [fetchResult] = useFetchComputeJobResultMutation();
  const [reportUrl, setReportUrl] = useState<string | undefined>();
  const [error, setError] = useState<{ message: string } | undefined>();
  const [isReportParsing, setIsReportParsing] = useState<boolean>(false);
  const handleReportParsing = useCallback(
    async (jobId: string) => {
      try {
        setIsReportParsing(true);
        const { data } = await fetchResult({
          variables: {
            input: {
              computeNodeId,
              dcrHash,
              driverAttestationHash,
              jobId,
            },
          },
        });
        const storeRef = data?.retrieveComputeJobResult?.result;
        if (!storeRef) {
          return;
        }
        const result = store.get<Uint8Array>(storeRef);
        if (!result) {
          return;
        }
        const { reportUrl, error } =
          await getSyntheticDataReportFromResults(result);
        setReportUrl(reportUrl);
        setError(error);
      } catch (error) {
        enqueueSnackbar(
          ...mapErrorToGeneralSnackbar(error, "Failed to get report")
        );
      } finally {
        setIsReportParsing(false);
      }
    },
    [
      setReportUrl,
      setError,
      setIsReportParsing,
      computeNodeId,
      enqueueSnackbar,
      dcrHash,
      fetchResult,
      driverAttestationHash,
      store,
    ]
  );
  useEffect(() => {
    if (open && jobId && !reportUrl && !error) {
      handleReportParsing(jobId);
    }
    if (!open && (error || reportUrl)) {
      setReportUrl(undefined);
      setError(undefined);
    }
  }, [
    jobId,
    open,
    reportUrl,
    error,
    setError,
    setReportUrl,
    handleReportParsing,
  ]);
  return (
    <Modal onClose={closeSdgQualityReportDialog} open={open}>
      <ModalDialog>
        <DialogContent>
          {isReportParsing ? (
            <Box
              sx={(theme) => ({
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                minHeight: theme.spacing(8),
              })}
            >
              <CircularProgress sx={{ "--CircularProgress-size": "16px" }} />
            </Box>
          ) : reportUrl ? (
            <iframe
              src={reportUrl}
              style={{
                border: "none",
                height: "calc(100% - 10px)",
                width: "100%",
              }}
              title="synthetic-data-quality-report"
            />
          ) : (
            <Alert color="danger">
              <AlertTitle>Quality report not available</AlertTitle>
              {error ? error.message : ""}
            </Alert>
          )}
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={closeSdgQualityReportDialog}>Close</Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default SyntheticDataReportDialog;
