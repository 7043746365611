import { DqLoader } from "@decentriq/components";
import { faTriangleExclamation } from "@fortawesome/pro-light-svg-icons";
import { Alert, Box, Button, Stack } from "@mui/joy";
import { useCallback, useMemo, useState } from "react";
import { EmptyData } from "components";
import { MediaDataRoomAudiencesTable } from "features/mediaDataRoom/components";
import {
  useMediaDataRoom,
  useMediaDataRoomInsightsData,
} from "features/mediaDataRoom/contexts";
import { type AudienceFilter } from "features/mediaDataRoom/models";
import { useAudiences } from "features/mediaDataRoom/wrappers";
import MediaDataRoomAudiencesToolbar from "./MediaDataRoomAudiencesToolbar";

const MediaDataRoomActivation: React.FC = () => {
  const [filter, setFilter] = useState<AudienceFilter>("all");
  const [searchValue, setSearchValue] = useState("");
  const { isPublisher, isAdvertiser, isDeactivated, hasAgency } =
    useMediaDataRoom();
  const {
    datasets: {
      isLoading: isDataRoomDataLoading,
      data: { hasRequiredData },
    },
    isPublisherAudienceBeingUpdated,
  } = useMediaDataRoomInsightsData();
  const [retryInProgress, setRetryInProgress] = useState(false);
  const { audiences, isAudiencesDataOutdated } = useAudiences();
  const loading = useMemo(() => {
    return (
      isDataRoomDataLoading ||
      audiences.loading ||
      audiences.status !== "COMPLETED"
    );
  }, [isDataRoomDataLoading, audiences.status, audiences.loading]);
  const isFiltered = useMemo(
    () => filter !== "all" || !!searchValue.trim(),
    [filter, searchValue]
  );
  const filteredAudiences = useMemo(() => {
    return audiences.computeResults?.filter((audience) => {
      const matchesStatus =
        !isPublisher || audience.mutable.status !== "published_as_intermediate";
      const matchesFilter = filter === "all" || audience.kind === filter;
      const matchesSearch = audience.mutable.name
        .toLowerCase()
        .trim()
        .includes(searchValue.toLowerCase().trim());
      return matchesStatus && matchesFilter && matchesSearch;
    });
  }, [audiences.computeResults, filter, isPublisher, searchValue]);

  const retryComputations = useCallback(async () => {
    setRetryInProgress(true);
    try {
      audiences.retry();
    } finally {
      setRetryInProgress(false);
    }
  }, [audiences, setRetryInProgress]);
  if (isAudiencesDataOutdated) {
    return (
      <EmptyData
        title={`Datasets have been updated. Please wait until the advertiser 
          ${hasAgency ? "or the agency" : ""} updates the audiences.`}
      />
    );
  }
  if (isPublisherAudienceBeingUpdated) {
    return (
      <EmptyData
        description={
          isPublisher
            ? "Please provision a datalab again."
            : "Activation will be available again soon."
        }
        title="Publisher audience is being updated."
      />
    );
  }
  if (!isDataRoomDataLoading && !hasRequiredData) {
    return <Alert>Waiting for both parties to provision data.</Alert>;
  }
  if (loading) {
    return (
      <Stack alignItems="center" height="100%" justifyContent="center">
        <DqLoader
          label={
            isAdvertiser
              ? audiences.status === "COMPUTING"
                ? "Computing audiences..."
                : audiences.status === "FETCHING"
                  ? "Retrieving audiences..."
                  : ""
              : isPublisher
                ? "Loading audiences..."
                : ""
          }
        />
      </Stack>
    );
  }
  if (audiences.error) {
    return (
      <EmptyData
        icon={faTriangleExclamation}
        title="Activated audiences could not be retrieved."
      >
        {!isDeactivated && (
          <Button
            color="neutral"
            loading={retryInProgress}
            onClick={retryComputations}
            variant="soft"
          >
            Retry
          </Button>
        )}
      </EmptyData>
    );
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <MediaDataRoomAudiencesToolbar
        onFilterChange={setFilter}
        onSearchChange={setSearchValue}
        searchValue={searchValue}
        selectedFilter={filter}
      />
      <MediaDataRoomAudiencesTable
        audiences={filteredAudiences || []}
        isFiltered={isFiltered}
      />
    </Box>
  );
};

MediaDataRoomActivation.displayName = "MediaDataRoomActivation";

export default MediaDataRoomActivation;
