import { type AppState, Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { Box } from "@mui/joy";
import React, { memo, useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Loading as Auth0Loading, UnauthorizedDialog } from "components";
import { useConfiguration } from "contexts";
import { clearDecentriqStorage } from "utils";

const Auth0Wrapper = memo<React.PropsWithChildren>(({ children }) => {
  const { auth0Audience, auth0ClientId, auth0Domain } = useConfiguration();
  const navigate = useNavigate();
  const onRedirectCallback = useCallback(
    (appState?: AppState) => {
      navigate(appState?.returnTo || window.location.pathname, {
        replace: true,
      });
    },
    [navigate]
  );
  const authParams = useMemo(
    () => ({
      audience: auth0Audience,
      redirect_uri: window.location.origin,
    }),
    [auth0Audience]
  );
  return (
    <Auth0Provider
      authorizationParams={authParams}
      clientId={auth0ClientId}
      domain={auth0Domain}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
    >
      <Auth0Consumer>{children}</Auth0Consumer>
    </Auth0Provider>
  );
});
Auth0Wrapper.displayName = "Auth0Wrapper";

const Auth0Error = memo<{ message?: string }>(
  ({ message = "Unknown error" }) => {
    const { logout } = useAuth0();
    return (
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          height: "100%",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <UnauthorizedDialog
          error={message}
          onClose={() => {
            clearDecentriqStorage();
            logout({ logoutParams: { returnTo: window.location.origin } });
          }}
          open={true}
        />
      </Box>
    );
  }
);

Auth0Error.displayName = "Auth0Error";

const Auth0Consumer = memo<React.PropsWithChildren>(({ children }) => {
  const { pathname } = useLocation();
  const { error, isLoading, loginWithRedirect } = useAuth0();
  if (pathname.startsWith("/signup")) {
    loginWithRedirect({
      authorizationParams: {
        screen_hint: "signup",
      },
    });
    return;
  }
  return error ? (
    <Auth0Error message={error?.message} />
  ) : isLoading ? (
    <Auth0Loading />
  ) : (
    children
  );
});
Auth0Consumer.displayName = "Auth0Consumer";

export default Auth0Wrapper;
