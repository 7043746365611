import { InfoTooltip } from "@decentriq/components";
import {
  useAddSyntheticNodeColumnMutation,
  useDeleteSyntheticNodeColumnMutation,
  useSetSyntheticNodeColumnsMutation,
  useUpdateSyntheticNodeColumnMutation,
} from "@decentriq/graphql/dist/hooks";
import {
  ColumnDataType,
  type DraftMatchNode,
  type DraftSqlNode,
  type DraftTableLeafNodeColumn,
  type SyntheticNodeColumn,
} from "@decentriq/graphql/dist/types";
import { faSync } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Button, Typography } from "@mui/joy";
import { List } from "@mui/material";
import { useDataRoom } from "contexts";
import { mapDraftDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import { isSameColumnMasked, mapColumnDataTypeToMaskType } from "../../models";
import { MaskingConfigRecordConstructor, SchemaButton } from "..";
import useSyntheticNodeMasking from "./useSyntheticNodeMasking";

interface MaskingConstructorProps {
  computeNodeId: string;
  dependencyId?: string;
  readOnly?: boolean;
}

interface MaskedColumnWithStatus extends Omit<SyntheticNodeColumn, "id"> {
  isExisting: boolean;
  isDeleted: boolean;
  columnId?: string;
  maskedColumnId?: string;
}

const MaskingConstructor: React.FC<MaskingConstructorProps> = ({
  computeNodeId,
  dependencyId,
  readOnly,
}) => {
  const {
    maskedColumns,
    isTabularDependency,
    originalColumns,
    shouldUseDraft,
    dependencyUpdatedAt,
    dependency,
  } = useSyntheticNodeMasking(computeNodeId, dependencyId);
  const { isPublished } = useDataRoom();
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const [updateSyntheticNodeColumn] = useUpdateSyntheticNodeColumnMutation({
    onError: (error) => {
      enqueueSnackbar(
        ...mapDraftDataRoomErrorToSnackbar(
          error,
          "Mask settings could not be updated."
        )
      );
    },
  });
  const [setSyntheticNodeColumns] = useSetSyntheticNodeColumnsMutation({
    onError: (error) => {
      enqueueSnackbar(
        ...mapDraftDataRoomErrorToSnackbar(
          error,
          "Mask settings could not be updated."
        )
      );
    },
  });
  const [addSyntheticNodeColumn] = useAddSyntheticNodeColumnMutation({
    onError: (error) => {
      enqueueSnackbar(
        ...mapDraftDataRoomErrorToSnackbar(
          error,
          "Mask settings could not be added."
        )
      );
    },
  });
  const [deleteSyntheticNodeColumn] = useDeleteSyntheticNodeColumnMutation({
    onError: (error) => {
      enqueueSnackbar(
        ...mapDraftDataRoomErrorToSnackbar(
          error,
          "Mask settings could not be deleted."
        )
      );
    },
  });
  const revisedMasksIndexes: number[] = [];
  const records: MaskedColumnWithStatus[] = shouldUseDraft
    ? originalColumns?.map((oC, index) => {
        let mask: SyntheticNodeColumn | undefined;
        const maskIndex = maskedColumns?.findIndex((m) =>
          isSameColumnMasked(oC, m)
        );
        if (maskIndex !== -1) {
          revisedMasksIndexes.push(maskIndex!);
          mask = maskedColumns![maskIndex!];
        }
        const isExisting = !!mask && maskIndex !== undefined;
        const shouldMaskColumn = isExisting && mask!.shouldMaskColumn;
        return {
          columnId: (oC as DraftTableLeafNodeColumn).id,
          createdAt: mask?.createdAt,
          dataType: oC.dataType,
          index,
          isDeleted: false,
          isExisting,
          maskType: mask?.maskType || mapColumnDataTypeToMaskType(oC.dataType),
          maskedColumnId: mask?.id,
          name: oC.name,
          shouldMaskColumn,
          updatedAt: mask?.updatedAt,
        };
      }) || []
    : maskedColumns?.map((m) => ({
        ...m,
        isDeleted: false,
        isExisting: true,
        isSelected: m.shouldMaskColumn,
        maskedColumnId: m.id,
      })) || [];
  if (shouldUseDraft && !!maskedColumns) {
    records.push(
      ...maskedColumns
        .filter((_, i) => !revisedMasksIndexes.includes(i))
        .map((m) => ({
          isDeleted: isTabularDependency,
          isExisting: true,
          isSelected: m.shouldMaskColumn,
          ...m,
          maskedColumnId: m.id,
        }))
    );
  }
  const isNonTabularOutdated =
    isTabularDependency &&
    !!dependencyUpdatedAt &&
    dependencyUpdatedAt !==
      (dependency as DraftSqlNode | DraftMatchNode).updatedAt;
  const isNonTabularUnmatched =
    !isTabularDependency &&
    (isNonTabularOutdated || (maskedColumns && maskedColumns.length === 0));
  const hasUnmatchedSchema =
    !readOnly &&
    (records.some((r) => r.isDeleted || !r.isExisting) ||
      isNonTabularUnmatched);
  const schemaButton = dependencyId ? (
    <SchemaButton
      computeNodeId={dependencyId}
      isOutdated={isNonTabularOutdated}
      onDetermined={(sourceColumns) => {
        setSyntheticNodeColumns({
          variables: {
            columns: (sourceColumns || []).map(
              ({ columnType, name }, index) => {
                const { primitiveType = 2 } = columnType || {};
                let dataType = ColumnDataType.Text;
                switch (primitiveType) {
                  case 0:
                    dataType = ColumnDataType.Int;
                    break;
                  case 1:
                    dataType = ColumnDataType.Text;
                    break;
                  case 2:
                    dataType = ColumnDataType.Float;
                    break;
                }
                return {
                  dataType,
                  index,
                  maskType: mapColumnDataTypeToMaskType(dataType),
                  name,
                  shouldMaskColumn: true,
                };
              }
            ),
            id: computeNodeId,
          },
        });
      }}
    />
  ) : undefined;
  return (
    <>
      {hasUnmatchedSchema ? (
        <Alert
          color="warning"
          endDecorator={
            isNonTabularUnmatched ? (
              schemaButton
            ) : (
              <Button
                data-testid="determine_schema_btn"
                onClick={() => {
                  setSyntheticNodeColumns({
                    variables: {
                      columns: (originalColumns || []).map(
                        ({ dataType, name }, index) => ({
                          dataType,
                          index,
                          maskType: mapColumnDataTypeToMaskType(dataType),
                          name,
                          shouldMaskColumn: true,
                        })
                      ),
                      id: computeNodeId,
                    },
                  });
                }}
                startDecorator={
                  <FontAwesomeIcon fixedWidth={true} icon={faSync} />
                }
                sx={{ marginLeft: ".5rem" }}
              >
                Update schema
              </Button>
            )
          }
          sx={{ marginTop: "1rem" }}
        >
          {isNonTabularUnmatched
            ? isNonTabularOutdated
              ? "Data source has changed."
              : "Selected data source is a computation."
            : "Data source schema has changed."}
        </Alert>
      ) : !isTabularDependency && !isPublished ? (
        <Alert
          color="info"
          endDecorator={schemaButton}
          sx={{ marginTop: "1rem" }}
        >
          Selected data source is a computation.
        </Alert>
      ) : undefined}
      {!!records.length && (
        <div
          style={{
            borderBottom: `1px solid lightgrey`,
            display: "flex",
            marginBottom: 4,
            marginTop: "1.5rem",
            padding: "8px 4px 8px 4px",
          }}
        >
          <div style={{ flex: 5 }}>Column name</div>
          <div
            style={{
              display: "flex",
              flex: 2,
              justifyContent: "center",
            }}
          >
            Data type
          </div>
          <div
            style={{
              display: "flex",
              flex: 2,
              justifyContent: "center",
            }}
          >
            Mask values{" "}
            <InfoTooltip
              tooltip={
                <>
                  Only values of columns where 'Mask values' is deselected will
                  appear in the result.
                  <br />
                  Example: If you have a column 'Name' in your dataset and you
                  don't want the names therein in the synthetic dataset, select
                  'Mask values' for this column.
                </>
              }
            />
          </div>
          <div
            style={{
              display: "flex",
              flex: 3,
              justifyContent: "center",
            }}
          >
            Mask with random value{" "}
            <InfoTooltip
              tooltip={
                <>
                  Choose the random value type that better replaces the original
                  value.
                  <br />
                  Examples of randomly generated values by type:
                  <br />
                  - Generic string: 07752cd861d462d3c082cc432743c9e604679f51
                  <br />
                  - Generic number: 87797244
                  <br />
                  - Name: Andrew Perez
                  <br />
                  - Address: 011 Boyd Fields Apt. 421
                  <br />
                  - Postcode: 85393
                  <br />
                  - Phone number: 033-758-6384
                  <br />
                  - Social Security Number: 817-90-4626
                  <br />
                  - Email: lmiller@sampson.biz
                  <br />
                  - Date: 2014-10-07
                  <br />
                  - Timestamp: 1475532549
                  <br />- IBAN: GB95PMXP51945293498083
                </>
              }
            />
          </div>
        </div>
      )}
      {records.length > 0 ? (
        <List style={{ marginBottom: "1rem", overflow: "auto", padding: 0 }}>
          {records.map(
            (
              {
                index,
                shouldMaskColumn,
                isExisting,
                isDeleted,
                maskType,
                dataType,
                name,
                maskedColumnId,
                columnId,
              },
              i
            ) => {
              return (
                <MaskingConfigRecordConstructor
                  dataType={dataType}
                  disabled={readOnly}
                  index={index}
                  isDeleted={isDeleted}
                  isExisting={isExisting}
                  isSelected={shouldMaskColumn}
                  key={maskedColumnId || columnId}
                  maskType={maskType}
                  maskedColumnId={maskedColumnId}
                  name={name}
                  onCheck={({ dataType, name }, index, maskedColumnId) => {
                    if (maskedColumnId) {
                      updateSyntheticNodeColumn({
                        variables: {
                          id: maskedColumnId,
                          shouldMaskColumn: true,
                        },
                      });
                    } else {
                      addSyntheticNodeColumn({
                        variables: {
                          column: {
                            dataType,
                            index,
                            maskType: mapColumnDataTypeToMaskType(dataType),
                            name,
                            shouldMaskColumn: true,
                          },
                          id: computeNodeId,
                        },
                      });
                    }
                  }}
                  onDelete={(maskedColumnId) => {
                    deleteSyntheticNodeColumn({
                      update: (cache) => {
                        cache.evict({
                          id: cache.identify({
                            __typename: "SyntheticNodeColumn",
                            id: maskedColumnId,
                          }),
                        });
                        cache.gc();
                      },
                      variables: {
                        id: maskedColumnId,
                      },
                    });
                  }}
                  onMaskUpdate={(maskedColumnId, maskType) => {
                    updateSyntheticNodeColumn({
                      variables: {
                        id: maskedColumnId,
                        maskType,
                      },
                    });
                  }}
                  onUncheck={(maskedColumnId) => {
                    updateSyntheticNodeColumn({
                      variables: {
                        id: maskedColumnId,
                        shouldMaskColumn: false,
                      },
                    });
                  }}
                />
              );
            }
          )}
        </List>
      ) : readOnly ? (
        <Typography level="body-sm" sx={{ mt: 1, pl: 0.5 }}>
          This {isTabularDependency ? "table" : "computation"} has no columns
        </Typography>
      ) : null}
    </>
  );
};

export default MaskingConstructor;
